import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import "@fontsource/jost";

import bodyArt1 from "../images/bodyart/1.jpg";
import bodyArt2 from "../images/bodyart/10.jpg";
import bodyArt3 from "../images/bodyart/2.jpg";
import beauty from "../images/beauty/19.jpg";
import portraiture from "../images/portraiture/5.jpg";
import fashion from "../images/fashion/2222.jpg";
import logo from "../images/small_logo_225.jpg";

import "@fontsource/nunito-sans";
import Crossfade from "../components/Crossfade";

const IndexPage = () => {
  const images = [bodyArt1, bodyArt2, bodyArt3, beauty, portraiture, fashion];

  return (
    <FlexWrapper>
      <TextContainer>
        <Logo src={logo}></Logo>
        <OpeningHeader>MIRIAM PIERZAK</OpeningHeader>
        <OpeningDescription>
          dancer • artist • model • based in London
        </OpeningDescription>

        <OpeningBio>The body says what words cannot.</OpeningBio>
        <EnterSite to="/beauty">ENTER</EnterSite>

        <ButtonsContainer>
          <NavLink to="/contact">
            <ContactButton>CONTACT ME</ContactButton>
          </NavLink>
          <a href="https://paypal.me/MiriamPierzak">
            <PaypalButton>SUPPORT ME</PaypalButton>
          </a>
        </ButtonsContainer>
      </TextContainer>
      <ImageContainer className="container">
        <Crossfade images={images} transition={2000} interval={2000} />
      </ImageContainer>
    </FlexWrapper>
  );
};

export default IndexPage;

const EnterSite = styled(Link)`
  color: black;
  font-size: 32px;
  margin-top: 100px;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
    text-decoration: underline;
  }
`;

const ImageContainer = styled.div`
  float: left;
  width: 50%;
  height: 100vh;
  overflow: hidden;

  position: relative;
  display: grid;
  grid-template-columns: 1fr;

  justify-items: center;

  @media screen and (max-width: 768px) {
    width: 700px;
    margin-top: 10px;
  }

  
`;

const TextContainer = styled.div`
  float: right;
  width: 50%;
  height: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
`;

const FlexWrapper = styled.div`
  overflow: hidden;
  display: flex;
  font-family: "Nunito Sans";
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

const OpeningHeader = styled.h1`
  color: black;
  font-size: 28px;
  margin-top: 50px;
  margin-bottom: 50px;
  letter-spacing: 0.14em;
  font-weight: 400;
  font-style: normal;
  font-family: "Playfair Display";

  @media screen and (max-width: 768px) {
    font-size: 19px;
  }

  @media screen and (max-width: 425px) {
    font-size: 18px;
  }
  @media screen and (max-width: 350px) {
    font-size: 14px;
  }
`;

const OpeningDescription = styled.p`
  color: black;
  font-size: 18px;
  display: block;
  text-align: center;
  letter-spacing: 0.2em;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const OpeningBio = styled.em`
  color: black;
  font-size: 18px;
  letter-spacing: 4px;
  margin-top: 100px;
  text-align: center;
  font-style: italic;
`;

const NavLink = styled(Link)``;

const ContactButton = styled.button`
  background-color: white;
  color: #3e3e3e;
  width: 140px;
  height: 55px;
  cursor: pointer;
  margin-top: 100px;
  margin-bottom: 50px;
  font-family: "Jost";
  font-size: 16px;
  font-weight: bolder;
  border: 2px solid #3e3e3e;
  margin-right: 5px;

  &:hover {
    background-color: #3e3e3e;
    color: white;
  }
`;
const PaypalButton = styled.button`
  margin-top: 100px;
  margin-bottom: 50px;
  margin-left: 5px;
  background-color: white;
  color: #3e3e3e;
  width: 140px;
  height: 55px;
  cursor: pointer;

  font-family: "Jost";
  font-size: 16px;
  font-weight: bolder;
  border: 2px solid #3e3e3e;

  &:hover {
    background-color: #3e3e3e;
    color: white;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
`;
const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin-top: 30px;
`;
